import Link from "next/link"
import Image from 'next/image';

export default function Footer3() {
    return (
        <>
            <footer>
                <div className="footer-area-three">
                    <div className="footer-top-three">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-7">
                                    <div className="footer-widget">
                                        <div className="fw-logo">
                                            <Link prefetch={false} href="/">
                                                <Image
                                                    width={250}
                                                    height={130}
                                                    src="/assets/img/logo/white_logo.webp" // Use a relative URL or an absolute URL for an external image
                                                    alt="white logo"
                                                    placeholder="logo" // Optional blur-up while loading
                                                    blurDataURL="/assets/img/logo/white_logo.webp" // Provide a low-res version or a data URL for the blur effect
                                                />
                                            </Link>
                                        </div>
                                        <div className="footer-content">
                                            <p className="p-break">
                                                OHC Medicinal Chemical Trading, based in Dubai, sources and delivers high-quality APIs, driving innovation in pharmaceuticals worldwide.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-5 col-sm-6 d-none d-lg-block"></div>
                                <div className="col-lg-2 col-md-5 col-sm-6">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Menu</h4>
                                        <div className="footer-link">
                                            <ul className="list-wrap">
                                                <li><Link prefetch={false} href="/about">About Us</Link></li>
                                                <li><Link prefetch={false} href="/services">Products & Services</Link></li>
                                                <li><Link prefetch={false} href="/inquire">Inquire</Link></li>
                                                <li><Link prefetch={false} href="/contact">Contact Us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-7">
                                    <div className="footer-widget">
                                        <h4 className="fw-title">Contact</h4>
                                        <div className="footer-info">
                                            <ul className="list-wrap">
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-pin" />
                                                    </div>
                                                    <div className="content">
                                                        <p>Dubai, UAE</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-phone-call" />
                                                    </div>
                                                    <div className="content">
                                                        <Link prefetch={false} href="tel:+971 52 885 3442">+971 52 885 3442</Link>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <i className="flaticon-clock" />
                                                    </div>
                                                    <div className="content">
                                                        <p>
                                                            Monday – Friday: 8 am – 5 pm, <br /> 
                                                            Saturday – Sat: 8 am – 1 pm, <br />
                                                            Sunday: <span>CLOSED</span>
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom-three">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="copyright-text-two text-center">
                                        <p>Copyright © OHC Chemicals | All Right Reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
