
import { useEffect, useState } from "react"
import BackToTop from '../elements/BackToTop'
import DataBg from "../elements/DataBg"
import PageHead from './PageHead'
import Footer3 from "./footer/Footer3"
import Header4 from "./header/Header4"
import Header6 from "./header/Header6"


export default function Layout({ headerStyle, footerStyle, headTitle, children }) {
    const [scroll, setScroll] = useState(0);

    // Moblile Menu
    const [isMobileMenu, setMobileMenu] = useState(false);
    const handleMobileMenu = () => {
        setMobileMenu(!isMobileMenu);
        !isMobileMenu ? document.body.classList.add("mobile-menu-visible") : document.body.classList.remove("mobile-menu-visible");
    };

    // Search Menu
    const [isSearch, setSearch] = useState(false);
    const handleSearch = () => setSearch(!isSearch);

    // Moblile Menu
    const [isOffcanvus, setOffcanvus] = useState(false);
    const handleOffcanvus = () => setOffcanvus(!isOffcanvus);

    const onScroll = () => {
        const scrollCheck = window.scrollY > 100;
        if (scrollCheck !== scroll) {
            setScroll(scrollCheck);
        }
    };

    useEffect(() => {
        const WOW = require('wowjs');
        window.wow = new WOW.WOW({
            live: false
        });
        window.wow.init();

        window.addEventListener("scroll", onScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [scroll]);
    
    return (
        <>
            <PageHead headTitle={headTitle} />
            <DataBg />

            {headerStyle == 4 ? <Header4 scroll={scroll} isMobileMenu={isMobileMenu} handleMobileMenu={handleMobileMenu} isSearch={isSearch} handleSearch={handleSearch} isOffcanvus={isOffcanvus} handleOffcanvus={handleOffcanvus} /> : null}
            {headerStyle == 6 ? <Header6 scroll={scroll} isMobileMenu={isMobileMenu} handleMobileMenu={handleMobileMenu} isSearch={isSearch} handleSearch={handleSearch} isOffcanvus={isOffcanvus} handleOffcanvus={handleOffcanvus} /> : null}

            <main className="fix">
                {children}
            </main>

            {footerStyle == 3 ? < Footer3 /> : null}

            <BackToTop />
        </>
    )
}
