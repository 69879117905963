import Link from "next/link";
import MobileMenu from "../MobileMenu";
import { useRouter } from "next/router";
import Image from 'next/image';

export default function Header4({ scroll, isMobileMenu, handleMobileMenu, isSearch, handleSearch, isOffcanvus, handleOffcanvus }) {
    const router = useRouter();

    return (
        <>
            <header className="header-style-five transparent-header">
                <div id="sticky-header" className={`menu-area ${scroll ? "sticky-menu" : ""}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mobile-nav-center">
                                <div className="mobile-nav-toggler" onClick={handleMobileMenu}><i className="fas fa-bars" /></div>
                                <div className="menu-wrap">
                                    <nav className="menu-nav">
                                        <div className="logo">
                                            <Link prefetch={false} href="/">
                                                <Image
                                                    width={300}
                                                    height={156}
                                                    src="/assets/img/logo/colored_logo.webp" // Use a relative URL or an absolute URL for an external image
                                                    alt="logo"
                                                    priority
                                                    layout="responsive"
                                                    loading="eager"
                                                    placeholder="logo" // Optional blur-up while loading
                                                    blurDataURL="/assets/img/logo/colored_logo.webp" // Provide a low-res version or a data URL for the blur effect
                                                />
                                            </Link>
                                        </div>
                                        <div className="navbar-wrap main-menu d-none d-lg-flex">
                                            <ul className="navigation">
                                                <li className={router.pathname === "/" ? "active" : ""}><Link prefetch={false} href="/">Home</Link></li>
                                                <li className={router.pathname === "/about" ? "active" : ""}><Link prefetch={false} href="/about">About Us</Link></li>
                                                <li className={router.pathname === "/services" ? "active" : ""}><Link prefetch={false} href="/services">Products & Services</Link></li>
                                                <li className={router.pathname === "/inquire" ? "active" : ""}><Link prefetch={false} href="/inquire">Inquire</Link></li>
                                                <li className={router.pathname === "/contact" ? "active" : ""}><Link prefetch={false} href="/contact">Contact Us</Link></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                {/* Mobile Menu  */}
                                <div className="mobile-menu">
                                    <nav className="menu-box">
                                        <div className="close-btn" onClick={handleMobileMenu}><i className="fas fa-times" /></div>
                                        <div className="nav-logo">
                                            <Link prefetch={false} href="/">
                                                <Image
                                                    width={199}
                                                    height={99}
                                                    src="/assets/img/logo/colored_logo.webp" // Use a relative URL or an absolute URL for an external image
                                                    alt="logo"
                                                    placeholder="logo" // Optional blur-up while loading
                                                    blurDataURL="/assets/img/logo/colored_logo.webp" // Provide a low-res version or a data URL for the blur effect
                                                />
                                            </Link>
                                        </div>
                                        <div className="menu-outer">
                                            <MobileMenu handleMobileMenu={handleMobileMenu}/>
                                        </div>
                                    </nav>
                                </div>
                                <div className="menu-backdrop" onClick={handleMobileMenu} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}
