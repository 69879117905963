import { useEffect, useState } from "react"

export default function BackToTop() {
    const [hasScrolled, setHasScrolled] = useState(false);

    const onScroll = () => {
        if (window.scrollY > 100 && !hasScrolled) {
            setHasScrolled(true);
        } else if (window.scrollY < 100 && hasScrolled) {
            setHasScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [hasScrolled]);
      
    return (
        <>
            {hasScrolled && (
                <div>
                   <a className="float-icon scroll-top scroll-to-target open" href="#__next">
                        <i className="fas fa-angle-up" />
                    </a>
                    <a className="float-icon message-icon scroll-to-target" href="/contact">
                        <i className="fas fa-comment-dots" />
                    </a>
                    <a className="float-icon telephone-icon" href="tel:+971528853442">
                        <i className="fas fa-phone" />
                    </a>
                    <a className="float-icon whatsapp-icon" href="https://api.whatsapp.com/send?phone=971528853442" target="_blank">
                        <i className="fab fa-whatsapp" size="xl"/>
                    </a>
                </div>
            )}
        </>
    )
}