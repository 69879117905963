import Link from "next/link";
import { useState, useEffect } from "react";

export default function MobileMenu({handleMobileMenu}) {
    return (
        <ul className="navigation">
            <li><Link prefetch={false} href="/" onClick={handleMobileMenu}>Home</Link></li>
            <li><Link prefetch={false} href="/about" onClick={handleMobileMenu}>About Us</Link></li>
            <li><Link prefetch={false} href="/services" onClick={handleMobileMenu}>Products & Services</Link></li>
            <li><Link prefetch={false} href="/inquire" onClick={handleMobileMenu}>Inquire</Link></li>
            <li><Link prefetch={false} href="/contact" onClick={handleMobileMenu}>Contact Us</Link></li>
        </ul>
    );
}
