import Head from "next/head";

const PageHead = ({ headTitle }) => {
  return (
    <>
      <Head>
        <title>{headTitle ? headTitle : "OHC Chemicals"}</title>
        <meta name="title" content="OHC Medicinal Chemical Trading" />
        <meta name="description" content="At OHC Medicinal Chemical Trading, we are dedicated to providing pharmaceutical companies with top-quality APIs. With a global network of trusted suppliers and a focus on industry standards, we ensure safe and effective medications. Partner with us for reliable sourcing and superior pharmaceutical ingredients." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.ohcchemicals.com/" />
        <meta property="og:title" content="OHC Medicinal Chemical Trading" />
        <meta property="og:description" content="At OHC Medicinal Chemical Trading, we are dedicated to providing pharmaceutical companies with top-quality APIs. With a global network of trusted suppliers and a focus on industry standards, we ensure safe and effective medications. Partner with us for reliable sourcing and superior pharmaceutical ingredients." />
        <meta property="og:image" content="https://ohc-website-frontend.vercel.app/assets/img/logo.jpeg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://www.ohcchemicals.com/" />
        <meta property="twitter:title" content="OHC Medicinal Chemical Trading" />
        <meta property="twitter:description" content="At OHC Medicinal Chemical Trading, we are dedicated to providing pharmaceutical companies with top-quality APIs. With a global network of trusted suppliers and a focus on industry standards, we ensure safe and effective medications. Partner with us for reliable sourcing and superior pharmaceutical ingredients." />
        <meta property="twitter:image" content="https://ohc-website-frontend.vercel.app/assets/img/logo.jpeg" />

      </Head>
    </>
  );
};

export default PageHead;
